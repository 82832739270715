import React, { useEffect } from 'react'
import { mdText, xlText, xxsText } from '../../style/text'
import { privacy_policy_data } from './utils/privacy-policy-data'

const PrivacyPolicy = () => {

  return (
    <div className={`py-[100px] px-[25px] lg:px-[100px] 2xl:px-[300px] `}>
      <h1 className={`${xlText} text-green-800 font-bold mb-5`}>Privacy Policy</h1>
      <div className={`flex flex-col text-green-800 gap-5`}>
        <h3 className={`${mdText} font-semibold`}>Who we are:</h3>
        <p className={`${xxsText} text-green-800`}>
          Jose’s Landscaping and Maintenance LLC (“we” or “us”) is committed to
          protecting the privacy of our website visitors and customers. This
          Privacy Policy outlines how we collect, use, and disclose personal
          information obtained through our website,
          http://opf.zks.mybluehost.me/website_d949a5d7/website_d949a5d7/ (“the
          Site”).
        </p>
      </div>
      <ol className='list-decimal text-green-800 ml-[35px] gap-5 mt-5 flex flex-col'>
        {privacy_policy_data.map(({ title, paragraphs }, i) => (
          <>
            <li className={`${mdText} font-semibold`} key={i}>
              {title}
            </li>
            {paragraphs.map((paragraph, i) => (
              <p key={i} className={`${xxsText} ml-[-35px]`}>
                {paragraph}
              </p>
            ))}
          </>
        ))}
      </ol>
    </div>
  )
}

export default PrivacyPolicy
