import React, { useEffect } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

const PageNotFound = () => {

  const src = 'https://dwsaur.com/wp-content/uploads/2019/10/underconstruction.png?w=1500'

  return (
    <div className='flex justify-center items-center py-20'>
      <LazyLoadImage effect='blur' src={src} alt='' className=' max-w-[800px]  w-full h-auto' />
    </div>
  )
}

export default PageNotFound