import React from 'react'
import { mdText, xlText, xxsText } from '../../style/text'
import { blog_data } from './utils/blog-data'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { Link } from 'react-router-dom'

const Blog = () => {
  return (
    <div className='flex flex-col bg-emerald-50 gap-20 pb-[100px] pt-[20px] px-[25px] lg:px-[100px] 2xl:px-[300px]'>
      <h1 className={`${xlText} text-green-800 font-bold flex justify-center`}>
        Blog
      </h1>
      <div className='flex flex-col gap-10 items-center '>
        {blog_data.map(({ title, description, linkUrl, imgSrc }, i) => (
          <div key={i} className={`flex items-start gap-5 xl:flex-row flex-col`}>
            <div className='flex items-center justify-center h-full xl:h-[410px] w-full md:w-[740px] xl:w-[750px] overflow-hidden'>
              <LazyLoadImage
                src={imgSrc}
                alt='image'
                effect='blur'
                className=' flex items-center justify-center'
              />
            </div>
            <div className={`flex flex-col w-full md:w-[740px] xl:w-[400px] gap-6`}>
              <Link to={linkUrl} className={`${mdText} text-[#059669] font-semibold`}>
                {title}
              </Link>
              <p className={`${xxsText}`}>{description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Blog
