import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { menu_data } from '../utils/menu-data'
import FocusTrap from 'focus-trap-react'
import { usePopper } from 'react-popper'
import { motion } from 'framer-motion'
import { ChevronDownIcon } from '@heroicons/react/24/outline'

const WideScreenSize = ({ setBookToday }: { setBookToday: any }) => {
  const [isPopperOpen, setIsPopperOpen] = useState(false)

  const popperRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  )

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start',
  })

  const closePopper = () => {
    setIsPopperOpen(false)
    buttonRef?.current?.focus()
  }
  const handleOutsideClick = (event: any) => {
    if (buttonRef.current && buttonRef.current.contains(event.target)) {
      // Prevent deactivation if the button is clicked
      return false
    }
    return true
  }

  const handleButton = () => {
    // console.log('activated')
    // const head = document.head
    // if (head.lastChild) {
    //   head.removeChild(head.lastChild)
    // }
    // const script = document.createElement('script')
    // script.src =
    //   'https://d3ey4dbjkt2f6s.cloudfront.net/assets/static_link/work_request_embed_snippet.js'
    // script.setAttribute('clienthub_id', '5789c19b-22fb-4fb5-b2f1-10e34f6c2290')
    // script.setAttribute('form_url', 'https://clienthub.getjobber.com/client_hubs/5789c19b-22fb-4fb5-b2f1-10e34f6c2293/public/work_request/embedded_work_request_form')
    // document.head.appendChild(script)
  }
  return (
    <>
      <Link to={'/'} className='text-green-800 font-bold w-[200px]'>
        Jose's Landscaping and Maintenance
      </Link>
      <div className='gap-8 lg:gap-14 lg:px-28 hidden md:flex'>
        {menu_data.map(({ location, title, subItems }) =>
          subItems ? (
            <div ref={popperRef} key={title}>
              <button
                ref={buttonRef}
                onClick={() => setIsPopperOpen(!isPopperOpen)}
                className={`flex items-center gap-1 ${isPopperOpen ? 'mx-0' : 'mx-[1px]'}`}
              >
                <h1 className={`text-green-800  ${isPopperOpen && 'font-semibold'}`}>{title}</h1>
                <ChevronDownIcon
                  className={`h-5 w-5 text-gray-600 transition-transform duration-300 ${isPopperOpen ? 'rotate-180' : ''}`}
                />
              </button>
              {isPopperOpen && (
                <FocusTrap
                  active
                  focusTrapOptions={{
                    initialFocus: false,
                    allowOutsideClick: true,
                    clickOutsideDeactivates: handleOutsideClick,
                    onDeactivate: closePopper,
                    fallbackFocus: buttonRef.current || undefined,
                  }}
                >
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    // NON FRAMER STUFF
                    tabIndex={-1}
                    style={popper.styles.popper}
                    className='dialog-sheet z-20 w-content shadow-xl bg-emerald-50 overflow-hidden rounded-b-lg focus:outline-none mt-[30px] ml-[-20px]'
                    {...popper.attributes.popper}
                    ref={setPopperElement}
                    role='dialog'
                    aria-label='DayPicker calendar'
                  >
                    {subItems.map(({ title, location }, i: number) => {
                      return (
                        <Link
                          to={location}
                          key={i}
                          className={`block w-full px-[20px] py-3 text-left text-green-800 hover:bg-green-100 hover:font-medium hover:px-[19px] hover:rounded`}
                        >
                          <motion.p
                            initial={{ x: -15, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{
                              duration: 0.25,
                              delay: 0.05 * i,
                            }}
                          >
                            {title}
                          </motion.p>
                        </Link>
                      )
                    })}
                  </motion.div>
                </FocusTrap>
              )}
            </div>
          ) : (
            <Link to={location} className='text-green-800' key={title} onMouseDown={handleButton}>
              {title}
            </Link>
          )
        )}
      </div>
      <Link
        to={'/'}
        onClick={() => setBookToday(true)}
        className='border-[#B6D3C5] border-[1px] hidden md:flex'
      >
        <h1 className='text-green-800 font-bold p-2 px-5'>Book Today</h1>
      </Link>
    </>
  )
}

export default WideScreenSize
