import React, { useEffect, useState } from 'react'

const JobberForm = ({ shadow }: { shadow?: boolean }) => {
  const [jobberForm, setJobberForm] = useState<any>()

  const getJobberForm = () => {
    const intervalId = setInterval(() => {
      const jobberFormContainer = document.getElementById(
        '5789c19b-22fb-4fb5-b2f1-10e34f6c2293'
      )
      const jobberIframe = jobberFormContainer?.children[0]
      if (jobberIframe) {
        setJobberForm(jobberIframe)
        clearInterval(intervalId)
      }
    }, 1000)
  }

  const returnJobberForm = () => {
    const jobberFormContainer = document.getElementById(
      '5789c19b-22fb-4fb5-b2f1-10e34f6c2293'
    )
    jobberFormContainer?.appendChild(jobberForm)
    console.log(jobberFormContainer)
  }

  const shadowToChildren = (container: HTMLElement, addShadow?: boolean) => {
    if (addShadow) {
      Array.from(container.children).forEach(child => {
        child.classList.add('shadow-md')
      })
    } else {
      Array.from(container.children).forEach(child => {
        child.classList.remove('shadow-md')
      })
    }
  }

  useEffect(() => {
    getJobberForm()

    if (jobberForm) {
      const container = document.getElementById('form-container')
      if (container) {
        container.appendChild(jobberForm)
        if (shadow) {
          shadowToChildren(container, true)
        } else {
          shadowToChildren(container)
        }
      }
    }

    return () => {
      if (jobberForm) {
        returnJobberForm()
      }
    }
  }, [jobberForm])

  return <div id='form-container' className='w-full flex justify-center' />
}

export default JobberForm
