import React from 'react'
import { mdText, smText, xlText, xxsText } from '../../../style/text'
import ArticleContainer from '../utils/ArticleContainer'

const contentData = [
  {
    title: 'Introduction',
    description:
      'Landscaping is more than just planting flowers or mowing lawns; it’s about creating an outdoor oasis that reflects your personality and enhances your lifestyle. From lush gardens to inviting patios, landscaping transforms ordinary spaces into extraordinary retreats. In this article, we explore the art and science of landscaping, offering insights into how to design, maintain, and enjoy your outdoor space to the fullest.',
  },
  {
    title: 'The Importance of Landscaping',
    description:
      'Landscaping is not only about aesthetics but also about functionality and environmental benefits. A well-designed landscape can increase property value, improve curb appeal, and provide numerous health and wellness benefits. Studies have shown that spending time in green spaces reduces stress, boosts mood, and promotes physical activity. Additionally, strategically placed trees and shrubs can provide shade, reduce energy costs, and improve air quality.',
  },
  {
    quote:
      'Landscaping is a living art that breathes life into our surroundings and soothes the soul.',
  },
  {
    title: 'Designing Your Landscape',
    description:
      'When designing your landscape, consider factors such as your property’s size, climate, soil type, and local regulations. Start by creating a master plan that outlines your goals, priorities, and budget. Incorporate elements such as hardscaping (patios, walkways, and retaining walls), softscaping (plants, trees, and flowers), and outdoor amenities (fire pits, water features, and seating areas). Pay attention to scale, proportion, and balance to create a cohesive and harmonious design.',
  },
  {
    title: 'Choosing the Right Plants',
    description:
      'Selecting the right plants is crucial for a successful landscape. Consider factors such as sun exposure, water requirements, and maintenance needs when choosing plants for your garden. Opt for a mix of native and drought-tolerant species that thrive in your local climate. Incorporate a variety of colors, textures, and heights to add visual interest and create depth in your landscape. Don’t forget to plan for seasonal changes and choose plants that provide year-round beauty.',
  },
  {
    title: 'Maintaining Your Landscape',
    description:
      'Once your landscape is installed, proper maintenance is essential to keep it looking its best. Regular tasks such as watering, pruning, fertilizing, and mulching help promote healthy growth and prevent problems such as pests and diseases. Consider hiring a professional landscaper for larger projects or ongoing maintenance to ensure your landscape stays in top condition year-round.',
  },
  {
    title: 'Enjoying Your Outdoor Space',
    description:
      'Ultimately, landscaping is about creating a space where you can relax, entertain, and connect with nature. Make the most of your outdoor space by incorporating features that enhance usability and comfort. Create cozy seating areas for lounging and dining, add ambient lighting for evening enjoyment, and consider adding amenities such as a fire pit or outdoor kitchen for entertaining guests. Whether you’re enjoying a quiet morning coffee or hosting a summer barbecue, your landscaped outdoor space should be a place where memories are made and cherished for years to come.',
  },
  {
    title: 'Conclusion',
    description:
      'Landscaping is a blend of art and science that transforms outdoor spaces into beautiful and functional retreats. By carefully designing, installing, and maintaining your landscape, you can create a space that reflects your personality, enhances your lifestyle, and brings you closer to nature. Whether you’re a gardening enthusiast or a novice homeowner, landscaping offers endless possibilities for creativity, enjoyment, and relaxation.',
  },
]

const ArtNdScienceOfLandscaping = () => {
  return (
    <ArticleContainer>
      <div className='flex flex-col'>
        <h1 className={`${xlText} text-green-800 font-semibold leading-tight`}>
          Transforming Your Outdoor Space: The Art and Science of Landscaping
        </h1>
        <br />
        <p className={`${xxsText} text-[#059669] font-semibold`}>
          March 15, 2024
        </p>
        <br />
        <div className='flex flex-col gap-7'>
          {contentData.map(({ title, description, quote }, index) =>
            quote ? (
              <div
                key={index}
                className={`${mdText} border-s-4 border-green-800 text-green-800 pl-[20px] font-semibold my-5`}
              >
                "{quote}"
              </div>
            ) : (
              <div className='flex flex-col gap-5' key={index}>
                <h1 className={`${smText} text-green-800`}>{title}:</h1>
                <p className={`${xxsText} text-green-800`}>{description}</p>
              </div>
            )
          )}
        </div>
      </div>
    </ArticleContainer>
  )
}

export default ArtNdScienceOfLandscaping
