import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='bg-black pt-[100px] flex justify-center px-[25px] lg:px-[100px] 2xl:px-[300px]'>
      <div className='flex flex-col items-start justify-center gap-20 lg:gap-10 max-w-[1200px]'>
        <div className='flex flex-col lg:flex-row gap-8  items-center text-center lg:text-start'>
          <h1 className='text-white text-[2em] '>
            Boost Your Health Starting from Your Own Backyard!
          </h1>
          <Link
            to={'/service'}
            className='bg-[#059669] flex items-center justify-center gap-5 w-[230px]'
          >
            <h1 className='text-white font-bold p-4 px-5 flex'>
              MAKE A RESERVATION
            </h1>
          </Link>
        </div>
        <div className='flex w-full sm:w-3/4 md:w-1/2 gap-8'>
          <div className='flex flex-col gap-4'>
            <h3 className='text-green-800 text-[22px]'>Freshen Your Air Today with Our Expert Solutions</h3>
            <a href='https://www.facebook.com/joseslandscaping1/'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                className='fill-white rounded w-full'
              >
                <path d='M22.675 0h-21.35C.594 0 0 .594 0 1.325v21.351C0 23.406.594 24 1.325 24h11.488v-9.294H9.847v-3.622h2.966V8.413c0-2.936 1.796-4.533 4.418-4.533 1.257 0 2.338.093 2.654.135v3.08l-1.823.001c-1.428 0-1.702.678-1.702 1.67v2.188h3.404l-.444 3.622h-2.96V24h5.789C23.406 24 24 23.406 24 22.676V1.325C24 .594 23.406 0 22.675 0z' />
              </svg>
            </a>
            <Link to={'./privacy-policy'} className='text-white'>
              Privacy Policy
            </Link>
          </div>
          <div className='flex flex-col gap-5'>
            <h5 className='text-white font-bold'>Navigation</h5>
            <ul className='text-green-800 flex flex-col gap-3'>
              <Link to={'/'}>Home</Link>
              <Link to={'/services'}>Services</Link>
              <Link to={'/gallery'}>Gallery</Link>
              <Link to={'/about-us'}>About Us</Link>
            </ul>
          </div>
        </div>
        <p className='text-white py-[80px]'>© 2024. All rights reiterated.</p>
      </div>
    </div>
  )
}

export default Footer
