import React, { useEffect } from 'react'
import CompanyCulture from './components/CompanyCulture'
import MeetTheFounder from './components/MeetTheFounder'
import ContactSection from './components/ContactSection'

const AboutUs = () => {
  return (
    <div className='flex flex-col'>
      <CompanyCulture/>
      <MeetTheFounder/>
      <ContactSection/>
    </div>
  )
}

export default AboutUs