import { ReactNode } from 'react'
import { motion } from 'framer-motion'
const SlideUpAnimation = ({
  className,
  backgroundColor,  
  children,
}: {
  className?: string,
  backgroundColor?: string,
  children: ReactNode
}) => {
  return (
    <div className={`bg-[${backgroundColor}]`}>
      <motion.div
        className={className}
        initial='hidden'
        whileInView='visible'
        viewport={{ once: true, amount: .1}}
        transition={{ duration: 1, ease: 'easeInOut' }}
        variants={{
          visible: { y: 0, opacity:1 },
          hidden: { y: 100, opacity: 0},
        }}
      >
        {children}
      </motion.div>
    </div>
  )
}

export default SlideUpAnimation
