import React from 'react'
import { mdText, xlText, xxsText } from '../../../style/text'
import imgSrc from '../../../images/AboutUs/our_philosophy_image.png'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

const CompanyCulture = () => {
  return (
    <div className='flex flex-col bg-emerald-50 gap-5 pb-[100px] pt-[20px] px-[25px] lg:px-[100px] 2xl:px-[300px]'>
      <h1
        className={`${xlText} text-green-800 font-bold flex justify-center`}
      >
        About Us
      </h1>
      <div className='flex items-center justify-center h-[535px]'>
        <LazyLoadImage
          src={imgSrc}
          alt='image'
          effect='blur'
          className='max-w-[400px] flex items-center justify-center'
        />
      </div>
      <div className='flex flex-col gap-8'>
        <h3 className={`${mdText} text-green-800 font-semibold`}>Our Philosophy</h3>
        <p className={`${xxsText} text-green-800`}>
          At Jose’s Landscaping LLC, we believe in transforming outdoor spaces
          into beautiful and functional environments that exceed our clients’
          expectations. Our philosophy revolves around customer satisfaction,
          quality craftsmanship, and attention to detail. With a focus on both
          commercial and residential landscaping, we strive to create outdoor
          areas that enhance the overall aesthetic appeal of properties while
          also maximizing functionality and usability.
        </p>
        <p className={`${xxsText} text-green-800`}>
          We take pride in our team of skilled professionals who are dedicated
          to delivering exceptional service from start to finish. From effective
          planning and design to skilled installation and ongoing maintenance,
          we are committed to ensuring that every project is completed to the
          highest standards. At Jose’s Landscaping LLC, we understand the
          importance of creating outdoor spaces that not only look great but
          also provide long-term value and enjoyment for our clients.
        </p>
        <p className={`${xxsText} text-green-800`}>
          By combining our expertise with a passion for excellence, we aim to
          create outdoor landscapes that leave a lasting impression. Whether
          it’s designing a commercial property to attract customers or
          transforming a residential backyard into an oasis, we are here to turn
          your landscaping vision into reality. Choose Jose’s Landscaping LLC
          for all your landscaping needs and experience the difference
          firsthand.
        </p>
      </div>
    </div>
  )
}

export default CompanyCulture
