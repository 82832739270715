import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { menu_data } from '../utils/menu-data'
import { motion, AnimatePresence } from 'framer-motion'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { ChevronDownIcon } from '@heroicons/react/24/outline'

const framerSidebarBackground = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0, transition: { delay: 0.2 } },
  transition: { duration: 0.3 },
}

const framerSidebarPanel = {
  initial: { x: '100vw' }, // Start completely off-screen to the right
  animate: { x: '0vw' }, // Move to the right side of the screen
  exit: { x: '100vw' }, // Move back off-screen to the right
  transition: { duration: 0.3 },
}

const framerText = (delay: any) => {
  return {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    transition: {
      // type: 'tween',
      delay: 0.5 + delay / 10,
    },
  }
}

const SidebarMenu = ({ setIsSidebarOpen }: any) => {
  const [displaySubItems, setDisplaySubItems] = useState(
    menu_data.reduce((acc: any, item) => {
      if (item.subItems) {
        acc[item.title] = false
      }
      return acc
    }, {})
  )
  return (
    <>
      <motion.div
        {...framerSidebarBackground}
        aria-hidden='true'
        className='fixed bottom-0 left-0 right-0 top-0 z-40 bg-[rgba(0,0,0,0.1)] backdrop-blur-sm'
        onClick={() => setIsSidebarOpen(false)}
      ></motion.div>
      <motion.div
        {...framerSidebarPanel}
        className='fixed top-0 bottom-0 right-0 z-50 w-full h-full max-w-xs bg-white'
        aria-label='Sidebar'
      >
        <div className='flex items-center justify-between p-5 border-b-2 border-emerald-400'>
          <span className='text-[22px]'>Welcome</span>
          <button
            onClick={() => setIsSidebarOpen(false)}
            className='p-2'
            aria-label='close sidebar'
          >
            <XMarkIcon className='w-8 ' />
          </button>
        </div>
        <div className='relative overflow-auto h-[85%]'>
          <div className='flex flex-col'>
            {menu_data.map(({ location, title, subItems }, i) => {
              return subItems ? (
                <div className='w-full' key={title}>
                  <motion.button
                  key={title}
                    {...framerText(i)}
                    onClick={e => {
                      setDisplaySubItems((prev: object) => ({
                        ...prev,
                        [title]: !displaySubItems[title],
                      }))
                    }}
                    className={`flex w-full px-5 py-4 items-center gap-1 hover:bg-green-100 cursor-pointer`}
                  >
                    <h1
                      className={`text-green-800  ${displaySubItems[title] && 'font-semibold'}`}
                    >
                      {title}
                    </h1>
                    <ChevronDownIcon
                      className={`h-5 w-5 text-gray-600 transition-transform duration-300 ${displaySubItems[title] ? 'rotate-180' : ''}`}
                    />
                  </motion.button>
                  <AnimatePresence>
                    {displaySubItems[title] && (
                      <motion.div
                        className='flex flex-col h-full ml-4'
                        initial='initial'
                        animate='open'
                        exit='collapsed'
                        variants={{
                          initial: { height: 0 },
                          open: { height: 'auto', overflow: 'hidden' },
                          collapsed: {
                            height: 0,
                            position: 'relative',
                            overflow: 'hidden',
                          },
                        }}
                        transition={{
                          duration: 1,
                        }}
                      >
                        {subItems.map(({ title, location }, i) => (
                          <Link
                            key={title}
                            to={location}
                            className='px-5 py-4 hover:bg-green-100'
                            onClick={() => setIsSidebarOpen(false)}
                          >
                            {title}
                          </Link>
                        ))}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              ) : (
                <Link
                  key={title}
                  className='px-5 py-4 hover:bg-green-100 text-green-800 flex'
                  to={location}
                  onClick={() => setIsSidebarOpen(false)}
                >
                  <motion.span {...framerText(i)}>{title}</motion.span>
                </Link>
              )
            })}
          </div>
        </div>
      </motion.div>
    </>
  )
}

export default SidebarMenu
