import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Bars3Icon } from '@heroicons/react/24/solid'
import { AnimatePresence } from 'framer-motion'
import WideScreenSize from './components/WideScreenSize'
import SidebarMenu from './components/SidebarMenu'

const Sidebar = () => {
  const { pathname } = useLocation()
  const [bookToday, setBookToday] = useState(false)

  //SECTION - Handles the sidebar display
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  //SECTION - Goes to the top of the page (or to the contact page)
  useEffect(()=>{
    if(bookToday && pathname === '/'){
      const contactSection = document.querySelector('#contact');
      if (contactSection) {
        contactSection.scrollIntoView({
          behavior: 'smooth'
        });
      }
      setBookToday(false)
    }else{
      window.scrollTo(0, 0);
    }
  },[pathname])

  useEffect(()=>{
    if(bookToday && pathname === '/'){
      const contactSection = document.querySelector('#contact');
      if (contactSection) {
        contactSection.scrollIntoView({
          behavior: 'smooth'
        });
      }
      setBookToday(false)
    }
  },[bookToday])

  return (
    <div className='flex h-[90px] bg-emerald-50 items-center justify-between lg:justify-center px-[20px]'>
      <WideScreenSize setBookToday={setBookToday}/>
      <Bars3Icon
        className='w-12 visible md:hidden cursor-pointer'
        onClick={() => setIsSidebarOpen(true)}
      />
      <AnimatePresence>
        {isSidebarOpen && (
          <SidebarMenu setIsSidebarOpen={setIsSidebarOpen}/>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Sidebar
