import { Routes, Route } from 'react-router-dom'
import Blog from '../pages/Blog'
import ArtNdScienceOfLandscaping from '../pages/Blog/Articles/ArtNdScienceOfLandscaping'
import FiveBestTrendsInLandscaping from '../pages/Blog/Articles/FiveBestTrendsInLandscaping'
const BlogRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Blog />} />
      <Route path='/transforming-your-outdoor-space-art-science-of-landscaping' element={<ArtNdScienceOfLandscaping />} />
      <Route path='/top-5-best-trends-landscape-maintenance' element={<FiveBestTrendsInLandscaping />} />
    </Routes>
  )
}

export default BlogRoutes
