import React, { useEffect } from 'react'
import {
  lgText,
  mdText,
  smText,
  xlText,
  xxlText,
  xxsText,
} from '../../style/text'
import { service_data } from './utils/service-data'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import SlideUpAnimation from '../../components/common/SlideUpAnimation'

const Services = () => {
  return (
    <div className={`flex flex-col bg-emerald-50 `}>
      <div className='flex flex-col py-[40px] px-[25px] lg:px-[100px] 2xl:px-[300px] max-w-[2500px] w-full m-auto'>
        <h1 className={`${xlText} font-extrabold text-green-800`}>Services</h1>
        <div className='flex flex-col justify-center items-center bg-[#10b981] py-6 px-5 text-center'>
          <h1 className={`${xxlText}`}>Areas of Practice</h1>
          <p className={`${smText}`}>Click on Images to See the Gallery</p>
        </div>
      </div>
      <div className='bg-[#064e3b] m-auto grid grid-cols-1 xl:grid-cols-2 gap-4 p-3 xs:p-5 max-w-[1780px] w-full'>
        {service_data.map(({ title, imgSrc, descriptionList, linkURL }, i) => (
          <SlideUpAnimation key={i} className='flex flex-col gap-3 bg-[#f7f7f8] rounded p-5 xs:p-8'>
            <h1 className={`${lgText} text-[#00f]`}>
              {(i+1).toString().padStart(2, '0')}
            </h1>
            <h1 className={`${mdText} semi-bold text-green-800`}>{title}</h1>
            <div className='h-[520px] 2xl:h-[620px] overflow-hidden flex items-center 2xl:items-start justify-center'>
              <LazyLoadImage effect='blur' src={imgSrc} alt='' className=' min-w-[500px]  w-full h-auto' />
            </div>
            <ol className='list-disc flex flex-col gap-2 mt-3'>
              {descriptionList.map((description, i) => (
                <li key={i} className={`${xxsText} ml-[30px] text-green-800`}>
                  {description}
                </li>
              ))}
            </ol>
            <Link
              to={linkURL}
              className={`${xxsText} text-[#059669] underline`}
            >
              Browse More Images Here-
            </Link>
          </SlideUpAnimation>
        ))}
      </div>
    </div>
  )
}

export default Services
