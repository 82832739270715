export const privacy_policy_data = [
  {
    title: 'Information We Collect:',
    paragraphs: [
      'We may collect personal information from you when you visit our website, inquire about our services, or engage with us. This information may include:',
      '– Name',
      '– Contact information (email address, phone number, address)',
      '– Billing and payment details',
      '– Any other information you choose to provide',
      'We may also collect non-personal information automatically through cookies and similar technologies, such as your IP address, browser type, and device information.',
    ],
  },
  {
    title: 'Use of Information:',
    paragraphs: [
      'We use the information we collect for the following purposes:',
      '– To provide and maintain our services',
      '– To communicate with you about your inquiries, orders, and account',
      '– To process payments and fulfill orders',
      '– To improve our website and services',
      '– To comply with legal obligations',
    ],
  },
  {
    title: 'Disclosure of Information:',
    paragraphs: [
      'We may disclose your personal information to third parties in the following circumstances:',
      '– With service providers who assist us in operating our website and providing our services',
      '– With our professional advisors, such as lawyers and accountants',
      '– With law enforcement or government authorities when required by law or to protect our legal rights',
      '– With your consent or as otherwise disclosed at the time of collection',
    ],
  },
  {
    title: 'Data Security:',
    paragraphs: [
      'We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure.',
    ],
  },
  {
    title: 'Your Rights:',
    paragraphs: [
      'You have the right to access, correct, or delete your personal information. You may also object to the processing of your personal information or request that we restrict certain processing activities. To exercise these rights, please contact us using the information provided below.',
    ],
  },
  {
    title: 'Children’s Privacy:',
    paragraphs: [
      'Our website and services are not directed to individuals under the age of 18. We do not knowingly collect personal information from children. If you are a parent or guardian and believe your child has provided us with personal information, please contact us, and we will delete such information.',
    ],
  },
  {
    title: 'Changes to this Privacy Policy:',
    paragraphs: [
      'We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated policy on our website.',
    ],
  },
  {
    title: 'Contact Us:',
    paragraphs: [
      'If you have any questions or concerns about this Privacy Policy or our practices regarding your personal information, please contact us at:',
      'Jose’s Landscaping and Maintenance LLC',
      '717 3RD AVE S Kent',
      'Email: joses.maint@gmail.com',
    ],
  },
  {
    title: 'Collection of User Data from Facebook Ads:',
    paragraphs: [
      'In addition to the information collected directly through our website, we may also collect personal information from individuals who interact with our Facebook ads. This information may include:',
      '– Name',
      '– Contact information (email address, phone number)',
      '– Demographic information',
      '– Any other information provided through Facebook’s ad targeting options',
      'We use this information for lead generation purposes to reach potential customers who may be interested in our landscaping services. The data collected from Facebook ads is subject to the same privacy practices outlined in this Privacy Policy. If you have any questions or concerns about the collection of data through Facebook ads, please contact us using the information provided below.',
    ],
  },
]
