import React from 'react'
import HomePageContainer from '../../../utils/HomePageContainer'
import imageSrc1 from '../../../images/Home/ServiceValueSection/unlock_your_propertys_potential_image.png'
import imageSrc2 from '../../../images/Home/ServiceValueSection/meet_our_expert_team_image.png'
import { Link } from 'react-router-dom'
import { xlText, xsText } from '../../../style/text'
import SlideUpAnimation from '../../../components/common/SlideUpAnimation'

const titleTextStyle = `font-bold text-green-800 ${xlText}`
const descriptionTextStyle = `${xsText}`
const btnContainerStyle = `flex w-full sm:items-center sm:items-start gap-10 sm:flex-row flex-col `

const CompanyValueSection = () => {
  return (
    <SlideUpAnimation  backgroundColor='#ecfdf5' >
      <HomePageContainer className='flex flex-col gap-32 bg-[#DBEFE5]'>
        {/* SECTION - Row 2 : Unlock Your Property’s Potential! */}
        <div className='flex w-full gap-12 items-center flex-col-reverse md:flex-row'>
          <div className='flex flex-col gap-8 w-full'>
            <h1 className={titleTextStyle}>
              Unlock Your Property’s Potential!
            </h1>
            <p className={descriptionTextStyle}>
              Unlock the full potential of your residential or commercial
              property with our comprehensive landscaping solServicesutions.
              From lush gardens to inviting outdoor spaces, let’s collaborate to
              bring your vision to life!
            </p>
            <div className={btnContainerStyle}>
              <Link
                to={'/gallery'}
                className='bg-[#059669] flex items-center justify-center gap-5 w-[230px] '
              >
                <h1 className='text-white font-bold p-4 px-5 flex w-full'>
                  CHECKOUT OUR WORK
                </h1>
              </Link>
              <a href='#testimonial' className='text-[#059669] font-bold pl-8 '>
                LEARN MORE
              </a>
            </div>
          </div>
          <div className='w-full flex items-start'>
            <img
              src={imageSrc1}
              alt=''
              className='object-center object-cover relative overflow-clip '
            />
          </div>
        </div>
        {/* SECTION - Row 2 : Meet Our Expert Team */}
        <div className={'flex w-full gap-12 items-center flex-col md:flex-row'}>
          <div className='w-full'>
            <img
              src={imageSrc2}
              alt=''
              className='object-center object-cover relative overflow-clip'
            />
          </div>
          <div className='flex flex-col gap-8 w-full'>
            <h1 className={titleTextStyle}>Meet Our Expert Team</h1>
            <p className={descriptionTextStyle}>
              Get to know the dedicated professionals behind Jose’s Landscaping
              and Maintenance LLC. Our experienced team brings a wealth of
              knowledge and creativity to every project, ensuring exceptional
              results tailored to your residential or commercial property. With
              a passion for outdoor aesthetics and a commitment to customer
              satisfaction, we’re here to transform your landscape dreams into
              reality.
            </p>
            <div className={btnContainerStyle}>
              <a
                href={'#contact'}
                className='bg-[#059669] flex items-center justify-center gap-5 w-[230px]'
              >
                <h1 className='text-white font-bold p-4 px-5 flex'>
                  CONTACT US
                </h1>
              </a>
              <Link to={'/about-us'} className='text-[#059669] font-bold'>
                LEARN MORE
              </Link>
            </div>
          </div>
        </div>
      </HomePageContainer>
    </SlideUpAnimation>
  )
}

export default CompanyValueSection
