import imageURL1 from '../../../images/Home/ServiceSection/lawn_care_and_maintenance_image.png'
import imageURL2 from '../../../images/Home/ServiceSection/hardscape_construction_and_installation_image.png'
import imageURL3 from '../../../images/Home/ServiceSection/irrigation_system_services_images.png'

export const service_section_data = [
    {
        imageSrc: imageURL1,
        title: "Lawn Care and Maintenance",
        list: ['Mowing', 'Edging', 'Fertilization', 'Weed control']
    },
    {
        imageSrc: imageURL2,
        title: "Hardscape Construction and Installation",
        list: ['Patios and walkways', 'Retaining walls', 'Pergolas and arbors', 'Outdoor kitchens']
    },
    {
        imageSrc: imageURL3,
        title: "Irrigation System Services",
        list: ['Installation', 'Repair', 'Maintenance', 'Water-efficient upgrades']
    }
]