import React from 'react'
import { xxlText, lgText } from '../../../../style/text'
import SlideUpAnimation from '../../../../components/common/SlideUpAnimation'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

function importAll(r: any) {
  return r.keys().map(r)
}

const imageSrcList = importAll(
  require.context(
    '../../../../images/Services/Categories/Mulching',
    false,
    /\.(png|jpe?g|svg)$/
  )
)

const Mulching = () => {

  return (
    <div className={`flex flex-col bg-emerald-50 `}>
      <div className='flex flex-col py-[40px] px-[25px] lg:px-[100px] 2xl:px-[300px] '>
        <div className='flex flex-col justify-center items-center bg-[#10b981] py-6 px-5 text-center'>
          <h1 className={`${xxlText}`}>Mulching</h1>
          {/* <p className={`${smText}`}>Click on Images to See the Gallery</p> */}
        </div>
      </div>
      <div className='bg-[#064e3b] my-5 mx-0 xs:mx-4 md:mx-8  grid grid-cols-1 xl:grid-cols-2 gap-4 p-3 xs:p-5'>
        {imageSrcList.map(( imageSrc:string, i:number) => (
          <SlideUpAnimation
            key={i}
            className='flex flex-col gap-3 bg-[#f7f7f8] rounded p-5 xs:p-8'
          >
            <h1 className={`${lgText} text-[#00f]`}>
              {(i + 1).toString().padStart(2, '0')}
            </h1>
            <div className='h-[520px] 2xl:h-[620px] overflow-hidden flex items-center 2xl:items-start justify-center'>
              <LazyLoadImage
                effect='blur'
                src={imageSrc}
                alt=''
                className=' min-w-[500px]  w-full h-auto'
              />
            </div>
          </SlideUpAnimation>
        ))}
      </div>
    </div>
  )
}

export default Mulching
