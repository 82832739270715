import React from 'react'
import HomePageContainer from '../../../utils/HomePageContainer'
import { Link } from 'react-router-dom'
import backImageSrc from '../../../images/Home/CredentialsSection/back_image.png'
import frontImageSrc from '../../../images/Home/CredentialsSection/front_image.png'
import { lgText, xxsText } from '../../../style/text'

const CredentialsSection = () => {
  return (
    <HomePageContainer className='flex gap-12 items-center flex-col-reverse lg:flex-row'>
      <div className='w-full flex flex-col gap-8'>
        <h1 className={lgText}>LICENSED, BONDED AND INSURED</h1>
        <p className={xxsText}>
          At Jose’s Landscaping LLC, we prioritize the security and peace of
          mind of our clients. That’s why we are proud to be fully licensed,
          bonded, and insured. Our commitment to maintaining the highest
          standards of professionalism and accountability ensures that you can
          trust us with your landscaping needs, knowing that you are protected
          every step of the way.
        </p>
        <Link
          to={'/about-us'}
          className='bg-[#059669] flex items-center justify-center gap-5 w-[230px]'
        >
          <h1 className='text-white font-bold p-4 px-5 flex'>
            MAKE A RESERVATION
          </h1>
        </Link>
      </div>
      <div className='w-full relative h-[600px] flex items-center justify-center overflow-hidden'>
        <img
          src={backImageSrc}
          alt=''
          className='absolute rounded ml-[-180px] '
          width={700}
          // height={1000}
        />
        <img
          src={frontImageSrc}
          alt=''
          className='absolute ml-[170px] object-fit'
          width={400}
          // height={800}
        />
      </div>
    </HomePageContainer>
  )
}

export default CredentialsSection
