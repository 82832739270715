import { Routes, Route } from 'react-router-dom'
import Services from '../pages/Services'
import SodInstallation from '../pages/Services/Categories/SodInstallation'
import ArtificialGrass from '../pages/Services/Categories/ArtificialGrass'
import RetainingWall from '../pages/Services/Categories/RetainingWall'
import FrenchDrain from '../pages/Services/Categories/FrenchDrain'
import PaversAndPatio from '../pages/Services/Categories/PaversAndPatio'
import PressureWashing from '../pages/Services/Categories/PressureWashing'
import Deck from '../pages/Services/Categories/Deck'
import Mulching from '../pages/Services/Categories/Mulching'
import Painting from '../pages/Services/Categories/Painting'
import Trimming from '../pages/Services/Categories/Trimming'
import Renovations from '../pages/Services/Categories/Renovations'
import Concrete from '../pages/Services/Categories/Concrete'
import Fencing from '../pages/Services/Categories/Fencing'
import PageNotFound from '../pages/PageNotFound.tsx'

const ServiceRoutes = () => {

  return (
    <Routes>
      <Route path='/' element={<Services/>} />

      <Route path='/sod-installation' element={<SodInstallation/>}/>
      {/* <Route path='/artificial-grass' element={<ArtificialGrass/>}/> */}
      <Route path='/retaining-wall' element={<RetainingWall/>}/>
      <Route path='/french-drain' element={<FrenchDrain/>} />
      <Route path='/pavers-and-patio' element={<PaversAndPatio/>} />
      <Route path='/pressure-washing' element={<PressureWashing/>} />
      <Route path='/deck' element={<Deck/>} />
      <Route path='/mulching' element={<Mulching/>}/>
      {/* <Route path='/gravel' /> */}
      <Route path='/painting' element={<Painting/>}/>
      <Route path='/trimming' element={<Trimming/>}/>
      <Route path='/renovations' element={<Renovations/>}/>
      <Route path='/concrete' element={<Concrete/>} />
      {/* <Route path='/creekvine-design' /> */}
      <Route path='/fencing' element={<Fencing/>}/>
      {/* <Route path='/winter-services' /> */}
      <Route path='/*' element={<PageNotFound/>} />
    </Routes>

  )
}

export default ServiceRoutes