import React, { useEffect } from 'react'
import Banner from './components/Banner'
import ServiceSection from './components/ServiceSection'
import CompanyValueSection from './components/CompanyValueSection'
import CredentialsSection from './components/CredentialsSection'
import Testimonial from './components/TestimonialSection'
import ContactSection from './components/ContactSection'

const Home = () => {
  return (
    <div className='flex flex-col'>
      <Banner/>
      <ServiceSection/>
      <CompanyValueSection/>
      <CredentialsSection/>
      <Testimonial/>
      <ContactSection/>
    </div>
  )
}

export default Home