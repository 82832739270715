import sodInstallationImgSrc from '../../../images/Services/sod_installation_image.png'
import artificialGrassImgSrc from '../../../images/Services/artificial_grass_image.png'
import retainingWallImgSrc from '../../../images/Services/retaining_wall_image.png'
import frenchDrainImgSrc from '../../../images/Services/french_drain_image.png'
import paversAndPatioImgSrc from '../../../images/Services/pavers_and_patio_image.png'
import pressureWashingImgSrc from '../../../images/Services/pressure_washing_image.png'
import deckImgSrc from '../../../images/Services/deck_image.png'
import mulchingImgSrc from '../../../images/Services/mulching_image.png'
import gravelImgSrc from '../../../images/Services/gravel_image.png'
import paintingImgSrc from '../../../images/Services/painting_image.png'
import trimmingImgSrc from '../../../images/Services/trimming_image.png'
import renovationsImgSrc from '../../../images/Services/renovations_image.png'
import concreteImgSrc from '../../../images/Services/concrete_image.png'
import creekvineDesignImgSrc from '../../../images/Services/creekvine_design_image.png'
import fencingImgSrc from '../../../images/Services/fencing_image.png'
import winterServicesImgSrc from '../../../images/Services/winter_services_image.png'

export const service_data = [
  {
    title: 'Sod Installation',
    imgSrc: sodInstallationImgSrc,
    descriptionList: [
      'Instantly transforms the landscape with a lush, green lawn',
      'Provides immediate results and a uniform appearance',
      'Requires proper preparation of the area before installation',
      'Ensures rapid establishment of grass roots with proper watering and maintenance',
    ],
    linkURL: './sod-installation',
  },
  {
    title: 'Artificial Grass',
    imgSrc: artificialGrassImgSrc,
    descriptionList: [
      'Offers a low-maintenance alternative to natural grass',
      'Provides a consistently green and manicured appearance',
      'Resistant to drought, pests, and extreme weather conditions',
      'Requires occasional cleaning and brushing to maintain aesthetics',
      'Ideal for areas with limited water resources or heavy foot traffic',
    ],
    linkURL: './artificial-grass',
  },
  {
    title: 'Retaining Wall',
    imgSrc: retainingWallImgSrc,
    descriptionList: [
      'Adds structural support and prevents soil erosion',
      'Enhances the aesthetics of outdoor spaces',
      'Creates terraced landscapes for planting or seating areas',
      'Can be constructed using various materials such as stone, concrete, or timber',
      'Requires proper drainage to prevent water buildup behind the wall',
    ],
    linkURL: './retaining-wall',
  },
  {
    title: 'French Drain',
    imgSrc: frenchDrainImgSrc,
    descriptionList: [
      'Efficiently redirects excess water away from structures or landscapes',
      'Prevents water accumulation and potential damage to property',
      'Consists of a perforated pipe surrounded by gravel or rock',
      'Installed underground to ensure proper drainage without disrupting surface aesthetics',
      'Ideal for managing water runoff from heavy rainfall or irrigation systems',
    ],
    linkURL: './french-drain',
  },
  {
    title: 'Pavers and Patio',
    imgSrc: paversAndPatioImgSrc,
    descriptionList: [
      'Enhances outdoor aesthetics and functionality',
      'Provides durable and versatile surface options for walkways, driveways, and patios',
      'Available in various materials such as concrete, brick, or natural stone',
      'Offers customizable designs and patterns to suit any style or preference',
      'Low maintenance and easy to repair or replace individual pavers if damaged',
    ],
    linkURL: './pavers-and-patio',
  },
  {
    title: 'Pressure Washing',
    imgSrc: pressureWashingImgSrc,
    descriptionList: [
      'Efficiently removes dirt, grime, mold, and mildew from surfaces',
      'Restores the appearance of driveways, sidewalks, decks, and siding',
      'Helps prevent damage and deterioration caused by built-up debris',
      'Enhances curb appeal and property value',
      'Ideal for prepping surfaces for painting or sealing',
    ],
    linkURL: './pressure-washing',
  },
  {
    title: 'Deck',
    imgSrc: deckImgSrc,
    descriptionList: [
      'Provides a versatile outdoor living space for relaxation and entertainment',
      'Constructed from durable materials such as wood, composite, or PVC',
      'Enhances the aesthetics and functionality of residential and commercial properties',
      'Requires periodic maintenance to ensure longevity and safety',
      'Can be customized with various features like railings, stairs, and built-in seating ',
    ],
    linkURL: './deck',
  },
  {
    title: 'Mulching',
    imgSrc: mulchingImgSrc,
    descriptionList: [
      'Improves soil health and fertility by retaining moisture and preventing erosion',
      'Suppresses weed growth, reducing the need for herbicides',
      'Enhances the aesthetic appeal of landscaping beds with a neat and uniform appearance',
      'Insulates plant roots from extreme temperatures, protecting them from heat and cold stress',
      'Breaks down over time, enriching the soil with organic matter and nutrients',
    ],
    linkURL: './mulching',
  },
  {
    title: 'Gravel',
    imgSrc: gravelImgSrc,
    descriptionList: [
      'Offers excellent drainage, preventing water accumulation and reducing the risk of flooding',
      'Provides a durable and low-maintenance surface for pathways, driveways, and outdoor areas',
      'Comes in various colors and sizes, allowing for creative design options and customization',
      'Creates a natural and rustic aesthetic that complements different landscaping styles',
      'Can be used to delineate borders, highlight landscape features, or fill decorative elements',
    ],
    linkURL: './gravel',
  },
  {
    title: 'Painting',
    imgSrc: paintingImgSrc,
    descriptionList: [
      'Refreshes the appearance of fences, decks, and other outdoor structures, enhancing curb appeal',
      'Protects surfaces from weathering, UV damage, and moisture, prolonging their lifespan',
      'Provides an opportunity to customize colors and add personality to outdoor spaces',
      'Improves property value by maintaining a well-maintained and attractive exterior',
      'Offers a cost-effective way to update and revitalize outdoor areas without major renovations',
    ],
    linkURL: './painting',
  },
  {
    title: 'Trimming',
    imgSrc: trimmingImgSrc,
    descriptionList: [
      'Enhances the appearance of trees, shrubs, and hedges, promoting a neat and tidy landscape',
      'Encourages healthy growth by removing dead or overgrown branches and promoting airflow',
      'Reduces the risk of pests and diseases by eliminating potential hiding places and improving plant health',
      'Maintains safety by preventing overhanging branches from encroaching on structures or walkways',
      'Contributes to overall property maintenance, preserving the aesthetics and functionality of outdoor spaces',
    ],
    linkURL: './trimming',
  },
  {
    title: 'Renovations',
    imgSrc: renovationsImgSrc,
    descriptionList: [
      'Breathes new life into outdated or worn-out landscaping features, revitalizing the overall look of the property',
      'Allows for customization and personalization to meet changing needs and preferences',
      'Increases property value by modernizing and enhancing the appeal of outdoor spaces',
      'Provides an opportunity to incorporate innovative designs, materials, and technologies for improved functionality and aesthetics',
      'Enhances curb appeal and creates a positive first impression for visitors and passersby',
    ],
    linkURL: './renovations',
  },
  {
    title: 'Concrete',
    imgSrc: concreteImgSrc,
    descriptionList: [
      'Provides a durable and long-lasting surface for driveways, walkways, and patios',
      'Offers versatility in design options, including various textures, colors, and finishes',
      'Requires minimal maintenance and upkeep compared to other materials',
      'Resistant to weathering, stains, and abrasions, ensuring longevity and durability',
      'Enhances the overall aesthetics of the landscape while serving practical purposes',
    ],
    linkURL: './concrete',
  },
  {
    title: 'Creekvine Design',
    imgSrc: creekvineDesignImgSrc,
    descriptionList: [
      'Expert craftsmanship in outdoor furniture and accessories',
      'Wide selection of handcrafted cedar furniture',
      'Charming garden accents to enhance outdoor spaces',
      'Quality materials and attention to detail in every piece',
      'Diverse range of products to suit various styles and preferences',
      'Commitment to customer satisfaction and quality craftsmanship',
    ],
    linkURL: './creekvine-design',
  },
  {
    title: 'Fencing',
    imgSrc: fencingImgSrc,
    descriptionList: [
      'High-quality fencing solutions for residential and commercial properties',
      'Customizable options to meet specific needs and preferences',
      'Durable materials for long-lasting performance and security',
      'Professional installation services for a seamless process',
      'Variety of styles and designs to complement any landscape',
      'Enhance privacy, safety, and aesthetics with our fencing solutions',
    ],
    linkURL: './fencing',
  },
  {
    title: 'Winter Services',
    imgSrc: winterServicesImgSrc,
    descriptionList: [
      'Snow removal for driveways, walkways, and parking lots',
      'Ice management to prevent slips and falls',
      'Seasonal landscape maintenance to prepare for winter weather',
      'Winter plant protection and care to ensure plant health',
      'Emergency storm response services for severe weather events',
    ],
    linkURL: './winter-services',
  },
]
