import React from 'react'
import { smText, xsText, xxlText, xxsText } from '../../../style/text'
import SlideUpAnimation from '../../../components/common/SlideUpAnimation'
import JobberForm from '../../../components/common/JobberForm'

const ContactSection = () => {
  return (
    <SlideUpAnimation className='flex flex-col bg-emerald-50 pb-[120px] gap-14 py-[80px] px-[25px] lg:px-[100px] 2xl:px-[300px] justify-center items-center text-center'>
      <h1 className={`${xxlText} text-green-800`}>Contact</h1>
      <p className={`${xsText} text-green-800`}>
        Have a question or ready to get started on your landscaping project?
        We’re here to help! Contact us today to speak with one of our friendly
        representatives who will be happy to assist you. Whether you need more
        information about our services, want to schedule a consultation, or have
        specific questions about your project, we’re just a phone call or email
        away. Reach out to us now, and let’s work together to create the perfect
        outdoor space for your residential or commercial property.
      </p>
      <SlideUpAnimation className='flex flex-col bg-white rounded p-8 sm:p-10 text-left gap-3 text-green-800 shadow'>
        <h3 className={`${smText}`}>Get in touch</h3>
        <p className={`${xxsText}`}>
          We’re here to answer your questions and listen to your suggestions.
        </p>
        <p className={`${xxsText}`}>Service Areas:</p>
        <ol className={`${xxsText} flex flex-col list-disc ml-[30px]`}>
          <li>Kent</li>
          <li>Snoqualmie</li>
          <li>Maple Valley</li>
          <li>Sammamish Valley</li>
          <li>Seattle</li>
          <li>Bellevue</li>
          <li>Black Diamond</li>
          <li>Covington</li>
          <li>North Bend</li>
          <li>Tukwila</li>
          <li>Tacoma</li>
          <li>Kirkland</li>
        </ol>
        <p className={`${xxsText}`}>717 3rd Ave S, Kent, WA 98032</p>
        <div className='md:text-[14px] text-[13px] '>
          <p>(206) 914-8403</p>
          <p>jose.maint@gmail.com</p>
        </div>
      </SlideUpAnimation>
      <JobberForm shadow />
    </SlideUpAnimation>
  )
}

export default ContactSection
