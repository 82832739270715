import React from 'react'

const HomePageContainer = ({
  className,
  id,
  children,
}: {
  className?: string
  id?: string
  children?: React.ReactNode
}) => {

  return (
    <div
      className={`py-[100px] px-[25px] lg:px-[100px] 2xl:px-[300px] 6xl:px-[600px] 7xl:px-[700px] 8xl:px-[850px] ${className}`}
      id={id}
    >
      {children}
    </div>
  )
}

export default HomePageContainer
