import { useEffect, useState } from 'react'
import './App.css'
import { Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import Services from './pages/Services'
import AboutUs from './pages/AboutUs'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import PrivacyPolicy from './pages/PrivacyPolicy'
import PageNotFound from './pages/PageNotFound.tsx'
import ServiceRoutes from './routes/Service'
import BlogRoutes from './routes/Blog'

function App() {
  return (
    <div className='w-full h-full flex flex-col'>
      <Navbar />
      <div className=' w-full overflow-hidden z-unset'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/services/*' element={<ServiceRoutes />} />
          <Route path='/Blog/*' element={<BlogRoutes />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </div>
      <Footer />
    </div>
  )
}

export default App
