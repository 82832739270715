import React from 'react'
import ArticleContainer from '../utils/ArticleContainer'
import { mdText, smText, xlText, xsText, xxsText } from '../../../style/text'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import imgSrc from '../../../images/Blog/Articles/FiveBestTrendsInLandscaping/first-image.jpg'

const FiveBestTrendsInLandscaping = () => {
  return (
    <ArticleContainer>
      <div className='flex flex-col'>
        <h1 className={`${xlText} text-green-800 font-semibold leading-tight`}>
          Top 5 Best Trends In Landscape And Maintenance
        </h1>
        <br />
        <p className={`${xxsText} text-[#059669] font-semibold`}>
          March 15, 2024
        </p>
        <br />
        {/* FIXME - If possible turn this into an array of objects and loop through them instead
        of hard coding it like this */}
        <div className='flex flex-col gap-7 text-green-800'>
          <p>
            Are you ready to revolutionize your corporate campus design with
            landscape and maintenance? Let’s dive into some cutting-edge
            concepts that will breathe new life into dull office landscapes.
          </p>
          <p>
            From sustainable features to interactive elements, we’ll explore
            innovative ideas that not only enhance aesthetics but also
            prioritize landscape and maintenance benefits.
          </p>
          <div className='flex items-center justify-center max-h-[300px] rounded my-5 overflow-clip'>
            <LazyLoadImage
              src={imgSrc}
              effect='blur'
            />
          </div>
          <ol className='list-decimal flex flex-col gap-5 ml-5 md:ml-10'>
            <li>
              <b>Biophilic Design:</b> Incorporate nature-inspired elements into
              the campus design to enhance employee well-being and productivity.
              Think living green walls, indoor gardens, and natural light-filled
              atriums that blur the line between indoor and outdoor spaces.
            </li>
            <li>
              <b>Flexible Work Zones:</b> Create dynamic work environments that
              adapt to the changing needs of modern businesses. Design flexible
              work zones with movable furniture, modular partitions, and
              adaptable spaces that can accommodate various work styles and
              activities.
            </li>
            <li>
              <b>Technology Integration:</b> Embrace cutting-edge technology to
              enhance connectivity and collaboration across the campus.
              Integrate smart sensors, digital signage, and interactive displays
              to streamline communication, wayfinding, and resource management.
            </li>
            <li>
              <b>Sustainable Practices:</b> Implement eco-friendly initiatives
              to reduce the environmental footprint of the campus. Explore
              options like green roofs, rainwater harvesting systems, and
              renewable energy sources to promote sustainability and reduce
              operating costs.
              <a href='https://en.wikipedia.org/wiki/Green_roof'>
                Click here to learn more about green roofs.
              </a>
            </li>
            <li>
              <b>Artistic Expression:</b> Infuse creativity and artistic
              expression into the campus design to inspire and energize
              employees. Incorporate public art installations, sculptures, and
              murals that reflect the company’s values and culture while adding
              visual interest to outdoor spaces.
            </li>
            <li>
              <b>Wellness Amenities:</b> Prioritize employee health and wellness
              by offering amenities like fitness centers, meditation gardens,
              and outdoor recreational areas. Create opportunities for
              relaxation, exercise, and social interaction to promote work-life
              balance and employee satisfaction.
            </li>
            <li>
              <b>Community Engagement:</b> Foster a sense of community and
              connection among employees by designing spaces that encourage
              interaction and socialization. Consider adding communal gathering
              areas, outdoor seating, and event spaces for team-building
              activities and company events.
            </li>
          </ol>
          <p>
            By exploring these innovative design concepts, corporate campuses
            can become more than just places of work—they can evolve into
            vibrant hubs of creativity, collaboration, and inspiration.
          </p>
          <div className='flex flex-col gap-2'>
            <h1 className={`${mdText} font-semibold`}>
              Are you a business owner looking to make your commercial property
              more environmentally friendly?
            </h1>
            <h1 className={`${mdText} font-semibold`}>
              Incorporating sustainable landscaping practices is not only good
              for the planet but can also benefit your bottom line.
            </h1>
            <h1 className={`${mdText} font-semibold`}>
              Here are some eco-friendly landscaping ideas to help your business
              go green
            </h1>
          </div>
          <ul className='list-disc flex flex-col gap-5'>
            <li className={`${xsText} ml-5 md:ml-10`}>Choose Native Plants</li>
            <p>
              When planning your commercial landscaping, opt for native plants
              that are well-suited to your region’s climate and soil conditions.
              Native plants require less water, fertilizer, and maintenance
              compared to exotic species, making them a more sustainable choice
              for your landscaping project.
            </p>
            <p>
              Plus, native plants attract local wildlife like birds and
              butterflies, adding natural beauty to your outdoor space.
            </p>
            <li className={`${xsText} ml-5 md:ml-10`}>
              Install Drip Irrigation Systems
            </li>
            <p>
              Save water and reduce runoff by installing drip irrigation systems
              for your commercial landscaping. Unlike traditional sprinkler
              systems that waste water through evaporation and overspray, drip
              irrigation delivers water directly to the roots of plants,
              minimizing water waste and promoting healthy growth.
            </p>
            <p>
              By using water more efficiently, you can lower your water bills
              and minimize your environmental impact.
            </p>
            <li className={`${xsText} ml-5 md:ml-10`}>
              Incorporate Permeable Hardscapes
            </li>
            <p>
              Replace traditional concrete or asphalt surfaces with permeable
              hardscapes like porous pavers or gravel pathways to reduce
              stormwater runoff and promote groundwater recharge.
            </p>
            <p>
              Permeable surfaces allow rainwater to infiltrate the soil,
              preventing erosion, reducing flooding, and replenishing local
              aquifers.
            </p>
            <p>
              Additionally, permeable hardscapes can enhance the aesthetic
              appeal of your commercial property while supporting sustainable
              water management practices.
            </p>
            <li className={`${xsText} ml-5 md:ml-10`}>Opt for Eco-Friendly Mulching</li>
            <p>
              Choose organic mulches like wood chips, bark, or compost to
              conserve soil moisture, suppress weeds, and improve soil health in
              your commercial landscaping beds.
            </p>
            <p>
              Organic mulches break down over time, enriching the soil with
              nutrients and promoting microbial activity. Avoid synthetic
              mulches made from rubber or plastic, as they do not decompose and
              can harm the environment.
            </p>
            <p>
              By using eco-friendly mulching materials, you can enhance the
              sustainability of your landscaping practices while creating a
              vibrant and healthy outdoor environment for your business.
            </p>
            <p>
              <b>
                By implementing these sustainable landscaping practices, you can
                reduce your environmental footprint, conserve valuable
                resources, and create a beautiful and eco-friendly outdoor space
                for your business.
              </b>
            </p>
          </ul>
          <h1 className={`${mdText} font-bold`}>
            Why Seasonal Maintenance Matters
          </h1>
          <p>
            You know those first impressions that count? Well, for commercial
            properties, they’re everything! That’s why seasonal maintenance is
            like the red carpet treatment for your business. Picture this: it’s
            spring, the birds are chirping, the sun is shining, and your
            property is bursting with life. That’s the magic of seasonal
            maintenance – it keeps your commercial space looking fresh,
            inviting, and ready to impress.
          </p>
          <ol className='list-decimal flex flex-col gap-5'>
            <li className={`${smText} font-bold ml-5 md:ml-7`}>
              Keeping Up with the Joneses (Or Competitors)
            </li>
            <p>
              In the fast-paced world of business, standing out is key. But
              guess what? Your competitors are probably already on top of their
              landscaping game.
            </p>
            <p>
              Don’t get left in the dust! Seasonal maintenance ensures your
              property stays ahead of the curve.
            </p>
            <p>
              From manicured lawns to vibrant flower beds, maintaining your
              commercial landscape sends a clear message: you’re serious about
              your business and dedicated to providing top-notch service.
            </p>
            <li className={`${smText} font-bold ml-5 md:ml-7`}>
              Making a Lasting Impression
            </li>
            <p>
              Picture this scenario: a potential client pulls up to your office
              building for a meeting.
            </p>
            <p>
              As they step out of their car, what’s the first thing they see?
              That’s right – your landscaping! A well-maintained exterior sets
              the tone for the entire client experience.
            </p>
            <p>
              It shows that you pay attention to detail, take pride in your
              business, and care about creating a welcoming environment.
            </p>
            <p>
              Plus, who wouldn’t want to do business with a company that clearly
              invests in its image?
            </p>
            <p>
              You see, seasonal maintenance isn’t just about keeping up
              appearances; it’s about setting the stage for success.
            </p>
            <p>
              By prioritizing regular upkeep, you’re not only enhancing the
              aesthetics of your property but also investing in your brand’s
              reputation and long-term growth.
            </p>
            <p>
              So, don’t overlook the importance of seasonal maintenance for your
              commercial property – it’s the secret ingredient to making a
              lasting impression in the competitive landscape of business.
            </p>
          </ol>
          <h1 className={`${mdText} font-bold`}>
            Revolutionizing Spaces: Transforming Corporate Campuses, Urban
            Areas, and Retail Environments Using Landscape and Maintenance
          </h1>
          <ul className='list-disc flex flex-col gap-5'>
            <li className='font-bold ml-5 md:ml-10'>
              Transforming Corporate Campuses:
            </li>
            <p>
              You won’t believe the incredible transformations happening in
              corporate landscapes! Imagine lush greenery, vibrant flower beds,
              and inviting outdoor seating areas. These commercial landscaping
              projects aren’t just about aesthetics; they’re about creating
              dynamic spaces where employees can relax, collaborate, and thrive.
              From tech giants to financial institutions, companies are
              investing in landscape and maintenance to enhance employee
              satisfaction and boost productivity.
            </p>
            <li className='font-bold ml-5 md:ml-10'>
              Bringing Nature to Urban Settings:
            </li>
            <p>
              In bustling urban areas, green spaces are like hidden gems amid
              the concrete jungle. But forward-thinking businesses are changing
              that narrative by incorporating nature into their surroundings.
              Picture rooftop gardens with panoramic city views, vertical
              gardens adorning office buildings, and pocket parks nestled
              between skyscrapers. These innovative commercial landscaping
              projects not only beautify urban landscapes but also provide
              much-needed havens for city dwellers to unwind and connect with
              nature.
            </p>
            <li className='font-bold ml-5 md:ml-10'>Elevating Retail Environments:</li>
            <p>
              Shopping malls and retail centers are embracing the power of
              landscaping to attract customers and create memorable experiences.
              Picture pedestrian-friendly walkways lined with trees and seasonal
              flower displays, outdoor seating areas where shoppers can take a
              break, and vibrant plantings that add color and texture to the
              surroundings. These commercial landscaping projects go beyond mere
              aesthetics; they’re strategic investments that enhance the overall
              shopping experience and drive foot traffic to businesses.
            </p>
          </ul>
          <h1 className={`${smText} font-bold `}>Conclusion:</h1>
          <p>
            In conclusion, these examples of successful commercial landscaping
            projects demonstrate the transformative power of landscape and
            maintenance. Whether it’s revitalizing corporate campuses, greening
            urban settings, or enhancing retail environments, landscaping plays
            a crucial role in shaping the spaces where we live, work, and play.
          </p>
        </div>
      </div>
    </ArticleContainer>
  )
}

export default FiveBestTrendsInLandscaping

//FIXME - Might take a look at this later when if the blog page turn too big
// then make a better systematic way of doing this with just json data
// {contentData.map((data, index) => {
//   const { paragraphs, unorderedList, orderedList } = data
//   if (paragraphs) {
//     return (
//       <div className='flex flex-col gap-5' key={index}>
//         {handleParagraphs(paragraphs)}
//       </div>
//     )
//   } else if (unorderedList) {
//     // console.log(unorderedList, data)
//     return (
//       <div key={index}>{handleUnorderedLists(unorderedList)}</div>
//     )
//   } else if (orderedList) {
//     return <div key={index}>{handleOrderedLists(orderedList)}</div>
//   } else {
//     return <div key={index}></div>
//   }
// })}

// const handleParagraphs = (paragraphs: any, topic?: string) => {
//   if (!paragraphs) return []
//   //NOTE - for now im going to assume that given me the topic also means
//   //that I should make it bold as well
//   const keys = Object.keys(paragraphs)

//   const texts: any[] = []
//   keys.map((key: string) => {
//     let textSize = ''
//     switch (key) {
//       case 'xs':
//         textSize = xsText
//         break
//       case 'xxs':
//         textSize = xxsText
//         break
//       case 'xxsNdBold':
//         textSize = xxsText
//         break
//       case 'smNdBold':
//         textSize = `${smText} font-bold`
//         break
//       case 'mdNdBold':
//         textSize = `${mdText} font-bold`
//         break
//     }

//     paragraphs[key].map((text: string, index: number) => {
//       if (topic) {
//         texts.push(
//           <li key={`${index} ${text}`} className={`${textSize}`}>
//             <span className='font-bold'>{topic}</span>
//             {text}
//           </li>
//         )
//       } else {
//         texts.push(
//           <h1 key={`${index} ${text}`} className={`${textSize}`}>
//             {text}
//           </h1>
//         )
//       }
//     })
//   })

//   return texts
// }

// const handleOrderedLists = (listObj: any) => {
//   const { content, isTopicContentLinked, isTopicSmNdBold } = listObj

//   let listJSX: any[] = []
//   content.map((contentObj: any, index: number) => {
//     const { topic, paragraphs, linkUrl, linkText } = contentObj

//     if (isTopicContentLinked) {
//       const paragraph = handleParagraphs(paragraphs, topic)
//       if (linkUrl && linkText) {
//         paragraph.push(<a href={linkUrl}>{linkText}</a>)
//       }
//       console.log(paragraph)
//       listJSX.push(...paragraph)
//     } else {
//       listJSX.push(
//         <li
//           className={isTopicSmNdBold ? `${smText} text-bold` : ''}
//           key={`${index} ${contentObj}`}
//         >
//           {topic}
//           <div className='flex flex-col gap-5'>
//             {handleParagraphs(paragraphs)}
//           </div>
//         </li>
//       )
//     }
//   })
//   return (
//     <ol
//       className='list-decimal py-5 flex flex-col gap-5'
//       key={JSON.stringify(listObj)}
//     >
//       {listJSX}
//     </ol>
//   )
// }
// const handleUnorderedLists = (listObj: any) => {
//   const { content, isTopicBold } = listObj

//   let listJSX: any[] = []
//   content.map((contentObj: any, index: number) => {
//     const { topic, paragraphs } = contentObj
//     listJSX.push(
//       <li
//         className={isTopicBold ? `text-bold` : ''}
//         key={`${index} ${contentObj}`}
//       >
//         {topic}
//         <div className='flex flex-col gap-5'>
//           {handleParagraphs(paragraphs)}
//         </div>
//       </li>
//     )
//   })
//   return (
//     <ul
//       className='list-disc py-5 flex flex-col gap-5'
//       key={JSON.stringify(listObj)}
//     >
//       {listJSX}
//     </ul>
//   )
// }

// const contentData = [
//   {
//     paragraphs: {
//       xxs: [
//         `Are you ready to revolutionize your corporate campus design with landscape and maintenance? Let’s dive into some cutting-edge concepts that will breathe new life into dull office landscapes.`,
//         `From sustainable features to interactive elements, we’ll explore innovative ideas that not only enhance aesthetics but also prioritize landscape and maintenance benefits.`,
//       ],
//     },
//   },
//   {
//     orderedList: {
//       isTopicContentLinked: true,
//       content: [
//         {
//           topic: 'Biophilic Design:',
//           paragraphs: {
//             xs: [
//               `Incorporate nature-inspired elements into the campus design to enhance employee well-being and productivity.
//     Think living green walls, indoor gardens, and natural light-filled atriums that blur the line between indoor and outdoor spaces.`,
//             ],
//           },
//         },
//         {
//           topic: 'Flexible Work Zones:',
//           paragraphs: {
//             xs: [
//               `Create dynamic work environments that adapt to the changing needs of modern businesses.
//     Design flexible work zones with movable furniture, modular partitions, and adaptable spaces that can accommodate various work styles and activities.`,
//             ],
//           },
//         },
//         {
//           topic: 'Technology Integration:',
//           paragraphs: {
//             xs: [
//               `Embrace cutting-edge technology to enhance connectivity and collaboration across the campus. Integrate smart sensors, digital signage, and interactive displays to streamline communication, wayfinding, and resource management.`,
//             ],
//           },
//         },
//         {
//           topic: 'Sustainable Practices:',
//           paragraphs: {
//             xs: [
//               `Implement eco-friendly initiatives to reduce the environmental footprint of the campus.
//     Explore options like green roofs, rainwater harvesting systems, and renewable energy sources to promote sustainability and reduce operating costs.`,
//             ],
//           },
//           linkUrl: 'https://en.wikipedia.org/wiki/Green_roof',
//           linkText: `Click here to learn more about green roofs`,
//         },
//         {
//           topic: 'Artistic Expression:',
//           paragraphs: {
//             xxs: [
//               `Infuse creativity and artistic expression into the campus design to inspire and energize employees. Incorporate public art installations, sculptures, and murals that reflect the company’s values and culture while adding visual interest to outdoor spaces.`,
//             ],
//           },
//         },
//         {
//           topic: 'Wellness Amenities:',
//           paragraphs: {
//             xxs: [
//               `Prioritize employee health and wellness by offering amenities like fitness centers, meditation gardens, and outdoor recreational areas.
//     Create opportunities for relaxation, exercise, and social interaction to promote work-life balance and employee satisfaction.`,
//             ],
//           },
//         },
//         {
//           topic: 'Community Engagement:',
//           paragraphs: {
//             xxs: [
//               `Foster a sense of community and connection among employees by designing spaces that encourage interaction and socialization.
//     Consider adding communal gathering areas, outdoor seating, and event spaces for team-building activities and company events.`,
//             ],
//           },
//         },
//       ],
//     },
//   },
//   {
//     paragraphs: {
//       xxs: [
//         `By exploring these innovative design concepts, corporate campuses can become more than just places of work—they can evolve into vibrant hubs of creativity, collaboration, and inspiration.`,
//       ],
//     },
//   },
//   {
//     paragraphs: {
//       mdNdBold: [
//         `Are you a business owner looking to make your commercial property more environmentally friendly?`,
//         `Incorporating sustainable landscaping practices is not only good for the planet but can also benefit your bottom line.`,
//         `Here are some eco-friendly landscaping ideas to help your business go green`,
//       ],
//     },
//   },
//   {
//     unorderedList: {
//       content: [
//         [
//           {
//             topic: 'Choose Native Plants',
//             paragraphs: {
//               xxs: [
//                 'When planning your commercial landscaping, opt for native plants that are well-suited to your region’s climate and soil conditions. Native plants require less water, fertilizer, and maintenance compared to exotic species, making them a more sustainable choice for your landscaping project.',
//                 'Plus, native plants attract local wildlife like birds and butterflies, adding natural beauty to your outdoor space.',
//               ],
//             },
//           },
//           {
//             topic: 'Install Drip Irrigation Systems',
//             paragraphs: {
//               xxs: [
//                 `Save water and reduce runoff by installing drip irrigation systems for your commercial landscaping. Unlike traditional sprinkler systems that waste water through evaporation and overspray, drip irrigation delivers water directly to the roots of plants, minimizing water waste and promoting healthy growth.`,
//                 `By using water more efficiently, you can lower your water bills and minimize your environmental impact.`,
//               ],
//             },
//           },
//           {
//             topic: 'Incorporate Permeable Hardscapes',
//             paragraphs: {
//               xxs: [
//                 `Replace traditional concrete or asphalt surfaces with permeable hardscapes like porous pavers or gravel pathways to reduce stormwater runoff and promote groundwater recharge.`,
//                 `Permeable surfaces allow rainwater to infiltrate the soil, preventing erosion, reducing flooding, and replenishing local aquifers.`,
//                 `Additionally, permeable hardscapes can enhance the aesthetic appeal of your commercial property while supporting sustainable water management practices.`,
//               ],
//             },
//           },
//           {
//             topic: 'Opt for Eco-Friendly Mulching',
//             paragraphs: {
//               xxs: [
//                 `Choose organic mulches like wood chips, bark, or compost to conserve soil moisture, suppress weeds, and improve soil health in your commercial landscaping beds.`,
//                 `Organic mulches break down over time, enriching the soil with nutrients and promoting microbial activity. Avoid synthetic mulches made from rubber or plastic, as they do not decompose and can harm the environment.`,
//                 `By using eco-friendly mulching materials, you can enhance the sustainability of your landscaping practices while creating a vibrant and healthy outdoor environment for your business.`,
//               ],
//               xxsNdBold: [
//                 `By implementing these sustainable landscaping practices, you can reduce your environmental footprint, conserve valuable resources, and create a beautiful and eco-friendly outdoor space for your business.`,
//               ],
//             },
//           },
//         ],
//       ],
//     },
//   },
//   {
//     paragraphs: {
//       mdNdBold: [`Why Seasonal Maintenance Matters`],
//       xxs: [
//         `You know those first impressions that count? Well, for commercial properties, they’re everything! That’s why seasonal maintenance is like the red carpet treatment for your business. Picture this: it’s spring, the birds are chirping, the sun is shining, and your property is bursting with life. That’s the magic of seasonal maintenance – it keeps your commercial space looking fresh, inviting, and ready to impress.`,
//       ],
//     },
//   },
//   {
//     orderedList: {
//       isTopicSmNdBold: true,
//       content: [
//         {
//           topic: 'Keeping Up with the Joneses (Or Competitors)',
//           paragraphs: {
//             xxs: [
//               `In the fast-paced world of business, standing out is key. But guess what? Your competitors are probably already on top of their landscaping game.`,
//               `Don’t get left in the dust! Seasonal maintenance ensures your property stays ahead of the curve.`,
//               `From manicured lawns to vibrant flower beds, maintaining your commercial landscape sends a clear message: you’re serious about your business and dedicated to providing top-notch service.`,
//             ],
//           },
//         },
//         {
//           topic: 'Making a Lasting Impression',
//           paragraphs: {
//             xxs: [
//               `Picture this scenario: a potential client pulls up to your office building for a meeting.`,
//               `As they step out of their car, what’s the first thing they see? That’s right – your landscaping! A well-maintained exterior sets the tone for the entire client experience.`,
//               `It shows that you pay attention to detail, take pride in your business, and care about creating a welcoming environment.`,
//               `Plus, who wouldn’t want to do business with a company that clearly invests in its image?`,
//               `You see, seasonal maintenance isn’t just about keeping up appearances; it’s about setting the stage for success.`,
//               `By prioritizing regular upkeep, you’re not only enhancing the aesthetics of your property but also investing in your brand’s reputation and long-term growth.`,
//               `So, don’t overlook the importance of seasonal maintenance for your commercial property – it’s the secret ingredient to making a lasting impression in the competitive landscape of business.`,
//             ],
//           },
//         },
//       ],
//     },
//   },
//   {
//     paragraphs: {
//       mdNdBold: [
//         `Revolutionizing Spaces: Transforming Corporate Campuses, Urban Areas, and Retail Environments Using Landscape and Maintenance`,
//       ],
//     },
//   },
//   {
//     unorderedList: {
//       isTopicBold: true,
//       content: [
//         {
//           topic: 'Transforming Corporate Campuses:',
//           paragraphs: {
//             xxs: [
//               `You won’t believe the incredible transformations happening in corporate landscapes! Imagine lush greenery, vibrant flower beds, and inviting outdoor seating areas. These commercial landscaping projects aren’t just about aesthetics; they’re about creating dynamic spaces where employees can relax, collaborate, and thrive. From tech giants to financial institutions, companies are investing in landscape and maintenance to enhance employee satisfaction and boost productivity.`,
//             ],
//           },
//         },
//         {
//           topic: `Bringing Nature to Urban Settings:`,
//           paragraphs: {
//             xxs: [
//               `In bustling urban areas, green spaces are like hidden gems amid the concrete jungle. But forward-thinking businesses are changing that narrative by incorporating nature into their surroundings. Picture rooftop gardens with panoramic city views, vertical gardens adorning office buildings, and pocket parks nestled between skyscrapers. These innovative commercial landscaping projects not only beautify urban landscapes but also provide much-needed havens for city dwellers to unwind and connect with nature.`,
//             ],
//           },
//         },
//         {
//           topic: 'Elevating Retail Environments:',
//           paragraphs: {
//             xxs: [
//               `Shopping malls and retail centers are embracing the power of landscaping to attract customers and create memorable experiences. Picture pedestrian-friendly walkways lined with trees and seasonal flower displays, outdoor seating areas where shoppers can take a break, and vibrant plantings that add color and texture to the surroundings. These commercial landscaping projects go beyond mere aesthetics; they’re strategic investments that enhance the overall shopping experience and drive foot traffic to businesses.`,
//             ],
//           },
//         },
//       ],
//     },
//   },
//   {
//     paragraphs: {
//       smNdBold: [`Conclusion:`],
//       xxs: [
//         `In conclusion, these examples of successful commercial landscaping projects demonstrate the transformative power of landscape and maintenance. Whether it’s revitalizing corporate campuses, greening urban settings, or enhancing retail environments, landscaping plays a crucial role in shaping the spaces where we live, work, and play.`,
//       ],
//     },
//   },
// ]
