export const menu_data = [
  { title: 'Home', location: '/' },
  { title: 'Services', location: '/services', },
  { title: 'Gallery', subItems:  [
    { title: 'Sod Installation', location: '/services/sod-installation' },
    { title: 'Artificial Grass', location: '/services/artificial-grass' },
    { title: 'Retaining Wall', location: '/services/retaining-wall' },
    { title: 'French Drain', location: '/services/french-drain' },
    { title: 'Pavers and Patio', location: '/services/pavers-and-patio' },
    { title: 'Pressure Washing', location: '/services/pressure-washing' },
    { title: 'Deck', location: '/services/deck' },
    { title: 'Mulching', location: '/services/mulching' },
    { title: 'Gravel', location: '/services/gravel' },
    { title: 'Painting', location: '/services/painting' },
    { title: 'Trimming', location: '/services/trimming' },
    { title: 'Renovations', location: '/services/renovations' },
    { title: 'Concrete', location: '/services/concrete' },
    { title: 'Creekvine Design', location: '/services/creekvine-design' },
    { title: 'Fencing', location: '/services/fencing' },
    { title: 'Winter Services', location: '/services/winter-servicess' }
  ]},
  { title: 'Blog', location: '/blog', },
  { title: 'About Us', location: '/about-us', },
]
