import React from 'react'
import { mdText, xlText, xsText, xxsText } from '../../../style/text'
import imgSrc from '../../../images/AboutUs/meet_the_founder_image.png'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

const MeetTheFounder = () => {
  const descriptionText = `${xxsText} text-green-800`
  return (
    <div className='flex flex-col bg-[#f1faf5] gap-8 py-[80px] px-[25px] lg:px-[100px] 2xl:px-[300px]'>
      <div className='flex flex-col items-center justify-center text-center gap-2 sm:px-[50px] px-0'>
        <h1 className={`${xlText} text-green-800 font-bold `}>
          Meet the Founder
        </h1>
        <LazyLoadImage
          src={imgSrc}
          effect='blur'
          className='max-w-[440px] flex items-center justify-center'
        />
        <p className={`${xsText} text-[#00f]`}>Jose Orihuela</p>
        <p className='md:text-[14px] text-[13px] text-[#38685b]'>Founder/ General Manager</p>
        <p className={descriptionText}>
          Jose Orihuela, a Peruvian immigrant, turned his passion for
          landscaping into a thriving business. Starting small, he went
          door-to-door, rapidly increasing sales. Today, his company boasts
          impressive growth and customer satisfaction metrics, a testament to
          his dedication and entrepreneurial spirit.
        </p>
      </div>
      <div className='flex flex-col gap-8'>
        <h3 className={`${mdText} text-green-800 font-semibold`}>
          Our Team’s Mission
        </h3>
        <p className={descriptionText}>
          At Jose’s Landscaping LLC, our team is the heart and soul of our
          operation. We’re a diverse group of passionate individuals, each
          bringing unique skills and expertise to the table. From our dedicated
          landscapers to our friendly customer service representatives, every
          member of our team is committed to delivering top-notch service and
          exceeding our clients’ expectations.
        </p>
        <p className={descriptionText}>
          Meet our talented crew of landscapers, designers, and maintenance
          experts who work tirelessly to bring your landscaping vision to life.
          With years of experience in the industry, our team has the knowledge
          and skills to tackle any landscaping project, big or small. From
          concept to completion, we’re here to guide you through every step of
          the process and ensure that your outdoor space looks its best
          year-round.
        </p>
        <p className={descriptionText}>
          At Jose’s Landscaping LLC, we believe in the power of teamwork and
          collaboration. That’s why we foster a supportive and inclusive work
          environment where every team member feels valued and empowered to
          contribute their ideas and expertise. When you choose us for your
          landscaping needs, you’re not just hiring a company – you’re
          partnering with a team of dedicated professionals who are passionate
          about creating beautiful outdoor spaces.
        </p>
        <p className={descriptionText}>
          Get to know the faces behind Jose’s Landscaping LLC and discover why
          our team is the best choice for all your landscaping projects. With
          our commitment to quality, reliability, and customer satisfaction, you
          can trust us to bring your landscaping dreams to life. Choose Jose’s
          Landscaping LLC and experience the difference that our talented team
          can make for your property.
        </p>
      </div>
    </div>
  )
}

export default MeetTheFounder
