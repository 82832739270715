import React from 'react'
import HomePageContainer from '../../../utils/HomePageContainer'
import imageSrc from '../../../images/Home/ContactSection/contact_image.png'
import { smText, xsText, xxlText, xxsText } from '../../../style/text'
import JobberForm from '../../../components/common/JobberForm'
import { Link } from 'react-router-dom'
const ContactSection = () => {
  return (
    <HomePageContainer className='bg-emerald-50 flex flex-col gap-5'>
      <div className='max-w-[1200px] m-auto'>
        <div className='h-[400px] overflow-hidden relative flex items-center justify-center '>
          <div className='z-50 w-full flex flex-col text-center items-center justify-center px-4'>
            <h1 className={`${xxlText} font-bold text-white max-w-[600px]`}>
              Ready to Transform Your Outdoor Space?
            </h1>
            <p className={`text-white ${xsText} max-w-[600px]`}>
              Take the first step towards creating your dream landscape. Contact
              us today to schedule a consultation and bring your vision to life!
            </p>
          </div>
          <div className='absolute' id='contact'>
            <div className='z-30 w-full h-full absolute bg-emerald-500 bg-opacity-40 '></div>
            <img src={imageSrc} alt='' className='min-w-[800px]' />
          </div>
        </div>
        <div className='bg-white pt-10 rounded shadow-md'>
          <JobberForm />
        </div>
        <div className='flex gap-5 flex-col sm:flex-row'>
          <div className='bg-[#059669] p-10 text-white w-full rounded shadow-md'>
            <h1 className={`${smText}`}>Read our blog</h1>
            <p className={`${xxsText} py-5`}>
              Stay informed and inspired with our latest landscaping tips, trends,
              and insights. Explore our blog for valuable resources and ideas to
              enhance your outdoor spaces.
            </p>
            <Link to={'./blog'} className={`${xsText}`}>
              Read blog &rarr;
            </Link>
          </div>
          <div className='bg-[#DBEFE5] p-10 text-[#064E3B] w-full rounded shadow-md'>
            <h1 className={`${smText}`}>
              Get Started on Your Dream Landscape Today!
            </h1>
            <p className={`${xxsText} pb-5 pt-3`}>
              Contact us to schedule a consultation and bring your landscaping
              vision to life with our expert team.
            </p>
            <p className={`${xsText} text-[#059669] pb-5`}>joses.maint@gmail.com</p>
            <p className={`${xsText}`}>(206)914-8403</p>
          </div>
        </div>
      </div>
    </HomePageContainer>
  )
}

export default ContactSection
