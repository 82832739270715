export const xxlText = 'lg:text-[54px] md:text-[48px] text-[40px] '
export const xlText = 'lg:text-[48px] md:text-[42px] text-[36px]'
export const lgText = 'lg:text-[38px] md:text-[34px] text-[30px]'

export const mdText = 'lg:text-[32px] md:text-[28px] text-[25px]'

export const smText = 'lg:text-[24px] md:text-[22px] text-[20px]'
export const xsText = 'lg:text-[18px] md:text-[17px] text-[16px]'
export const xxsText = 'lg:text-[16px] md:text-[15px] text-[14px]'

export const listText = 'lg:text-[20px] md:text-[19px] text-[18px]'