import React, { ReactNode } from 'react'

const ArticleContainer = ({ children }: { children: ReactNode }) => {
  const px =
    'px-[25px] md:px-[100px] lg:px-[250px] xl:px-[350px] 2xl:px-[450px] 3xl:px-[580px]'

  const style = `flex flex-col bg-emerald-50 gap-20 pb-[100px] pt-[80px] ${px}`
  return <div className={style}>{children}</div>
}

export default ArticleContainer
