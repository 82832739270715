import artNdScienceImg from '../../../images/Blog/art_and_science_of_landscaping.png'
import trendsForLandscapingImg from '../../../images/Blog/trends_for_landscaping.jpg'

export const blog_data = [
  {
    title: `Transforming Your Outdoor Space: The Art and Science of Landscaping`,
    linkUrl: `./transforming-your-outdoor-space-art-science-of-landscaping`,
    description: `Introduction: Landscaping is more than just planting flowers or mowing lawns; 
    it’s about creating an outdoor oasis that reflects your personality and enhances your 
    lifestyle. From lush gardens to inviting patios, landscaping transforms ordinary spaces 
    into extraordinary retreats. In this article, we explore the art and science of landscaping, 
    offering insights into how to design,…`,
    imgSrc: artNdScienceImg,
  },
  {
    title: `Top 5 Best Trends In Landscape And Maintenance`,
    linkUrl: `./top-5-best-trends-landscape-maintenance`,
    description: `Are you ready to revolutionize your corporate campus design with landscape and 
    maintenance? Let’s dive into some cutting-edge concepts that will breathe new life into 
    dull office landscapes.`,
    imgSrc: trendsForLandscapingImg
  },
]
